<script>
import Loading from '../../general/Loading.vue'
export default {
  name: 'ValidationInvitePage',
  components: {
    Loading
  },
  data () {
    return {
      inviteId: null,
      showSpinner: true,
      messageError: null
    }
  },
  created () {
    this.inviteId = this.$route.query.invite_id
    this.getInvite()
  },
  computed: {
    userToken () {
      return this.$store.getters.getUserToken
    },
    getUser () {
      return this.$store.getters.getUser
    },
    userWorkspaces () {
      return this.$store.getters.getUserWorkspaces
    }
  },
  methods: {
    getInvite () {
      this.$store.dispatch('attemptGetInvite', this.inviteId)
        .then(({ data }) => {
          if (data) {
            if (this.getUser && this.getUser.email && this.getUser.email.toLowerCase() !== data.email.toLowerCase()) {
              this.$store.commit('setInviteDetails', { companyName: data.subdomain, inviteEmail: data.email, userEmail: this.getUser.email, showMessageInvite: true, title: 'global:ops', text: 'home.invite.my' })
              this.$router.push({ name: 'individual.home' })
            } else {
              if (data.registered && !this.userToken) {
                this.$router.push({ name: 'auth.invite.login', query: { email: data.email, companyName: data.company.name, subdomain: data.company.subdomain, avatar: data.company.avatar, invite_id: this.inviteId } })
              } else if (!data.registered) {
                window.location.href = `${this.baseUrl}/register?invite_id=${this.inviteId}&company_name=${encodeURIComponent(data.company.name)}&subdomain=${data.company.subdomain}&email=${encodeURIComponent(data.email)}&avatar=${encodeURIComponent(data.company.avatar)}&help=1`
              } else if (this.getUser.email.toLowerCase() === data.email.toLowerCase()) {
                this.acceptInvite().then(() => {
                  window.location.href = `${this.baseUrl}/c/${data.company.subdomain}/tos?path=/c/${data.company.subdomain}&subdomain=${data.company.subdomain}&help=1`
                })
              }
            }
          }
        })
        .catch((err) => {
          if (['expiredInvite', 'errorVerifyingUserInvite'].includes(err.response.data.error)) {
            this.showSpinner = false
            this.messageError = err.response.data.error.indexOf('expiredInvite') !== -1 ? 'settings.admincenter.invite.labels:expired' : 'settings.admincenter.invite.labels:invalid'
          }
        })
    },
    acceptInvite () {
      return this.$store.dispatch('attemptAcceptInvite', { inviteId: this.inviteId })
        .then(() => { })
        .catch(res => {
          this.$store.dispatch('attemptSetFeedbackMsg', {
            type: 'error',
            title: this.$t('registration.form:error')
          })
        })
    }
  }
}
</script>
<template>
  <div>
    <Loading class="loading-app--wrapper" :showSpinner="showSpinner" :text="messageError" :showText="!showSpinner" />
  </div>
</template>
<style lang="scss">
.loading-app--wrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20vh;
}
</style>
